(function () {
    angular.module('informaApp')
        .service('Environments', Environments);

    function Environments() {
        return {
            local: 'local',
            staging: 'staging',
            production: 'production'
        };
    }
})();